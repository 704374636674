<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('product_purchase_report.supplier') }}</label>
                <multiselect v-model="supplier"
                             :placeholder="$t('product_purchase_report.supplier')"
                             label="full_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('product_purchase_report.item') }}</label>
                <multiselect v-model="item" :placeholder="$t('product_purchase_report.item')"
                             label="name"
                             track-by="id"
                             :options="items"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getItems('name', $event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('product_purchase_report.from_date') }}</label>
                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('product_purchase_report.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('group_by') }}</label>
                <select name="" id="" v-model="filters.group_by" class="custom-select">
                  <option value="product">{{ $t('product_purchase_report.product') }}</option>
                  <option value="supplier">{{ $t('product_purchase_report.supplier') }}</option>
                  <option value="employee">{{ $t('product_purchase_report.employee') }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('order_by') }}</label>
                <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option value="asc">{{ $t('asc') }}</option>
                  <option value="desc">{{ $t('desc') }}</option>
                </select>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('product_purchase_report.currency') }}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>


    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
            <div class="col-md-12 mb-5">
              <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">
                  <div class="col-12 mt-10" :id="'summaryTable'+filters.group_by+'Title'">
                    <!-- Clients Installments - Group By Client -->
                    <h4 class="text-center">{{ $t('product_purchase_report.product_purchase_report') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                                    {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span>
                    </h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                      <button class="dropdown-item" @click="convertTableToExcelReportSummary">
                        <i class="la la-file-excel"></i>{{ $t('excel') }}
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable'+filters.group_by, 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable'+filters.group_by, 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="col-12 mt-10" v-if="dataChartList">
                    <bar-chart :data-list="dataChartList" :currency-name="currency_name"></bar-chart>
                  </div>
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered" :id="'summaryTable'+filters.group_by">
                        <thead>

                        <tr id="myRow4">
                          <th>{{ $t('product_purchase_report.' + filters.group_by) }}</th>
                          <th>{{ $t('product_purchase_report.quantity') }}</th>
                          <th>{{ $t('product_purchase_report.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row2, index2) in data_summary" :key="'z'+getRandom()+index2">
                          <td>{{ row2.label }}</td>
                          <td>{{ row2.qty }}</td>
                          <td>{{ row2.total }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr v-if="dataTotalSummary">
                          <td>{{ $t('product_purchase_report.total') }}</td>
                          <th>{{ dataTotalSummary.total_quantity }}</th>
                          <th>{{ dataTotalSummary.total_total }}</th>
                        </tr>
                        </tfoot>
                      </table>

                    </div>

                  </div>
                </div>
              </div>

            </div>
          </b-tab>

          <b-tab :title="$t('details')" :active="tab == 'details'">
            <div class="col-md-12 mb-5">
              <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row">
                  <div class="col-12 mt-10" :id="'summaryTableDetails'+filters.group_by+'Title'">
                    <!-- Clients Installments - Group By Client -->
                    <h4 class="text-center">{{ $t('product_purchase_report.product_purchase_report') }}</h4>
                    <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }}
                                    {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span>
                    </h5>
                  </div>
                  <div class="col-12 mt-10 d-flex justify-content-end">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                      <button class="dropdown-item" @click="convertTableToExcelReport">
                        <i class="la la-file-excel"></i>{{ $t('excel') }}
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <div class="col-12 mt-10" v-if="dataChartList">
                    <bar-chart :data-list="dataChartList" :currency-name="currency_name"></bar-chart>
                  </div>
                  <div class="col-12 mt-10">
                    <div class="table-responsive">
                      <table class="table table-bordered" :id="'summaryTableDetails'+filters.group_by">
                        <thead>

                        <tr id="myRow">
                          <th>{{ $t('product_purchase_report.id') }}</th>
                          <th>{{ $t('product_purchase_report.name') }}</th>
                          <th>{{ $t('product_purchase_report.description') }}</th>
                          <th>{{ $t('product_purchase_report.product_code') }}</th>
                          <th>{{ $t('product_purchase_report.date') }}</th>
                          <th>{{ $t('product_purchase_report.type') }}</th>
                          <th>{{ $t('product_purchase_report.added_by') }}</th>
                          <th>{{ $t('product_purchase_report.supplier') }}</th>
                          <th>{{ $t('product_purchase_report.unit_price') }}</th>
                          <th>{{ $t('product_purchase_report.total_taxes') }}</th>
                          <th>{{ $t('product_purchase_report.quantity') }}</th>
                          <th>{{ $t('product_purchase_report.total') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(row, index) in data">
                          <tr :key="'c'+index">
                            <td colspan="12">{{ row.label }}</td>
                          </tr>
                          <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                            <td>{{ row2.id }}</td>
                            <td>{{ row2.name }}</td>
                            <td>{{ row2.description }}</td>
                            <td>{{ row2.product_code }}</td>
                            <td>{{ row2.date }}</td>
                            <td>{{ row2.type }}</td>
                            <td>{{ row2.added_by }}</td>
                            <td>{{ row2.supplier_name }}</td>
                            <td>{{ row2.price_per_unit }}</td>
                            <td>{{ row2.total_taxes }}</td>
                            <td>{{ row2.qty }}</td>
                            <td>{{ row2.subtotal_price }}</td>
                          </tr>
                          <tr :key="'d'+index">
                            <td colspan="10">{{ $t('product_purchase_report.total') }}</td>
                            <td>{{ row.total.total_quantity }}</td>
                            <td>{{ row.total.total_total }}</td>
                          </tr>
                        </template>

                        </tbody>
                        <tfoot>
                        <tr v-if="dataTotal">
                          <td colspan="10">{{ $t('product_purchase_report.total') }}</td>
                          <th>{{ dataTotal.sum_total_quantity }}</th>
                          <th>{{ dataTotal.sum_total_total }}</th>
                        </tr>
                        </tfoot>
                      </table>

                    </div>

                  </div>
                </div>
              </div>

            </div>
          </b-tab>
        </b-tabs>
        <div class="row">
          <div class="col-12 mt-5 text-center">
            <button class="btn btn-warning" @click="loadMore">
              <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
              {{ $t('load_more') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import BarChart from "./components/BarChart";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

export default {
  name: "index-product-purchase-report",
  components: {'bar-chart': BarChart},

  data() {
    return {
      mainRoute: '/reports/purchases/products',
      mainRouteDependency: 'base/dependency',

      data: [],
      data_summary: [],
      dataTotal: [],
      dataTotalSummary: [],
      dataChartList: null,
      suppliers: [],
      currencies: [],
      items: [],


      data_more_details: [],
      details_total_sum: [],


      filters: {
        supplier_id: null,
        group_by: null,
        from_date: null,
        to_date: null,
        order_by: null,
        currency_id: null,
        item_id: null,
      },
      currency_name: null,
      supplier: null,
      item: null,
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
    }
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
    item: function (val) {
      if (val) {
        this.filters.item_id = val.id;
      } else {
        this.filters.item_id = null;
      }
    },
    'filters.group_by': function () {
      this.data = [];
      this.dataTotal = [];
    },

  },
  methods: {
    loadMore() {
      this.page = this.page + 1;
      this.getData();
      this.getDataChartList();
      this.getDataSummary();
    },

    convertTableToExcelReport() {
      this.setReportLog('excel', 'product purchase report');
      TableToExcel.convert(document.getElementById('summaryTableDetails' + this.filters.group_by), {
        name: this.$t('product_purchase_report.product_purchase_report') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    convertTableToExcelReportSummary() {
      this.setReportLog('excel', 'product purchase report');
      TableToExcel.convert(document.getElementById('summaryTable' + this.filters.group_by), {
        name: this.$t('product_purchase_report.product_purchase_report') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    async getAuthUser() {
      await ApiService.get(`/get_auth`).then((response) => {
        this.user = response.data.data;
        this.currency_name = response.data.data.currency_name;
        if (this.user) {
          this.user_name = this.user.name;
        }
      });
    },

    // selectFilter() {
    //     this.getData();
    // },

    doFilter() {
      this.getData();
      this.getDataChartList();
      this.getDataSummary();
    },
    resetFilter() {
      this.supplier = null;

      this.filters.supplier_id = null;
      // this.filters.group_by = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.order_by = null;
      this.getData();
      this.getDataChartList();
      this.getDataSummary();


    },
    dataReport() {
      return this.data;
    },

    getDataChartList() {
      ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1), dataType: 'chart'}}).then((response) => {
        this.dataChartList = response.data.data;
      });
    },

    getData() {
      ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data = response.data.data.data;
        this.dataTotal = response.data.data.total;
      });
    },
    getDataSummary() {
      ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1), dataType: 'list'}}).then((response) => {
        this.data_summary = response.data.data;
        this.dataTotalSummary = response.data.total;
      });
    },


    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },

    printData(tableId, type) {
      this.setReportLog('pdf', 'product purchase report');
      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)

      exportPDFInnerAction(tableId, type, this.$t('product_purchase_report.product_purchase_report'), this.$t('product_purchase_report.product_purchase_report'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.product_purchase_report")}]);
    this.filters.group_by = this.$route.query.group_by ? this.$route.query.group_by : null;

    this.getCurrencies();
    this.getItems();
    this.getAuthUser();

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>